<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="pz-cms-home-holder">
      <v-card
        v-for="(item, i, j) in summaryCounters"
        :key="i"
        class="pz-cms-home-item mx-auto"
        outlined
        :style="`background-color:${summaryCounterCandI[j].color}`"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-icon color="white" class="mt-4" x-large>{{summaryCounterCandI[j].icon}}</v-icon>
            <v-list-item-title class="headline white--text mb-4" style="text-transform: capitalize">
              {{ i }}: {{ item }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
  </div>
  </div>
</template>

<script>
import { fetchWebsites, fetchSummaryGraphs } from "@/services";
import TheSidebar from "@/components/TheSidebar";

export default {
  name: "Dashboard",
  components: {
    TheSidebar,
  },
  data() {
    return {
      websites: null,
      summaryCounters: {},
      summaryCounterCandI: [
        {color: "#40BAD1", icon: "mdi-label-multiple"},
        {color: "#3FD89E", icon: "mdi-vector-square"},
        {color: "#5461DA", icon: "mdi-comment-question-outline"},
        {color: "#9948D7", icon: "mdi-television"},
        {color: "#E743A0", icon: "mdi-account-group-outline"},
        {color: "#FF574A", icon: "mdi-sitemap"}
      ]
    };
  },
  mounted() {
    this.getWebsites();
    this.getSummaryGraphs();
  },
  methods: {
    audioEnded() {
      console.log("yeeey")
    },
    async getWebsites() {
      await fetchWebsites().then((res) => {
        if (res.data) {
          this.websites = res.data;
        } else {
          // this.errors = "server error";
        }
      });
    },
    async getSummaryGraphs() {
      await fetchSummaryGraphs().then((res) => {
        if (res.data) {
          this.summaryCounters = res.data.data;
        }
      });
    },
  },
};
</script>
<style scoped>
.pz-cms-home-wrapper {
  width: 100%;
}
.pz-cms-home-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5%;
}
.pz-cms-home-item {
  width: 30%;
  height: 130px;
  margin: 10px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.pz-cms-home-item i {
  margin-bottom: 10px;
}
</style>